body {
  background-image: url("logo.png");
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: center;
}


.form-login {
  width: 20%;
  margin: 2rem auto;
}

.clearfix::after {
  content: "";
  clear: both;
  display: table;
}

.inline-checkbox .ui.checkbox {
    margin-right: 1rem;
}

.ui.blue.statistic>.value, .ui.blue.statistics .statistic>.value, .ui.statistics .blue.statistic>.value {
  color: #37536d;
}

.form-login h1 {
  margin-bottom: 25px;
  text-align: center;
  font-size: 1.7rem;
  margin-top: 35px;
}

.header-menu {
  background-color: transparent;
  opacity: 1;
  color: rgba(0,0,0,.85);
  font-size: .92857143em;
  text-transform: uppercase;
  font-weight: 700;
  margin-right: 10px;
}

.logo-menu {
  height: 40px;
  margin: 10px;
}

.menu-sistemas .ui.button {
  margin: 10px 5px;
}

@media ( max-width : 768px) {
  .logo-menu {
    display: none;
  }

  .grupo-graficos .ui.segment {
    margin: 1rem 0;
  }

  .barra-numeros .ui.segment {
    margin: 5px 0;
  }

  .form-login {
    width: 90%;
  }

}


@media ( max-width : 600px) {

  .tabela-com-detalhes.ui.segment {
    width: 345px;
  }

  table.dnxTable {
    border: none !important;
  }
  
  table.dnxTable th,
  table.dnxTable td {
    padding: 1em 0.5em;
    position: relative;
  }

  table.dnxTable tr,
  table.dnxTable td,
  table.dnxTable tfoot {
    display: block !important;
    width: 100%;
  }

  table.denxTable tbody tr {
    border-top: 1px solid rgba(34, 36, 38, 0.1);
  }

  table.denxTable tfoot tr {
    border: none !important;
  }

  table.dnxTable td {
    border-top: none !important;
    border-left: none !important;
  }
  
  table.dnxTable tbody tr {
    padding: 0.25em 0;
    border: 1px solid rgba(34, 36, 38, 0.2);
  }

  table.dnxTable tbody tr:not(:last-child) {
    margin-bottom: 0.25em;
  }

  /** important1: hide theader **/
  table.dnxTable thead {
    display: none !important;
  }

  table.dnxTable tbody td {
    padding: 0.3em 0.5em 0.3em 7.5em !important;
  }

  .dnxTable td:not(:last-child) {
    border-bottom: 1px solid rgba(34, 36, 38, 0.1);
  }

  /** important2: use data-label attribute as a table header **/
  table.dnxTable tbody td:before {
    content: attr(data-label);
    left: 0.5em;
    position: absolute;
    top: 0.15em;
    font-weight: bold;
    text-overflow: ellipsis;
    white-space: nowrap;
    width: 6em;
    overflow: hidden;
  }
}

.ui.breadcrumb .section {
  padding: 3px 0 !important;
}