.barra-numeros  {
    padding-left: 1em;
    display: flex;
    justify-content: space-between;
}

.barra-numeros .icon {
    font-size: 0.8em;
}

.barra-numeros .button {
    padding: 0.5em;
    margin-right: 0.5em !important;
    margin-left: 0.5em !important;
    box-shadow: none !important;
}

.ui.statistics.barra-numeros .statistic>.value {
    color: rgb(55, 83, 109);
    text-align: center;
    width: 100%;
    margin-bottom: 5px;
}

.ui.statistics.barra-numeros .ui.button {
    padding: 10px 5px;
}

.ui.statistics.barra-numeros .statistic>.label {
    font-size: 0.9em;
}

.ui.statistics.barra-numeros .statistic>.value {
    font-size: 3.5rem !important;
}

.barra-numeros .button:hover {
    box-shadow: 0 0 0 1px rgba(34, 36, 38, .35) inset, 0 0 0 0 rgba(34, 36, 38, .15) inset !important;
}

